<template>
	<div class="newsDetails_box">
		<div class="crumbs">
			<span class="current">当前位置：</span>
			<el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item><span class="cu" @click="toHome">首页</span></el-breadcrumb-item>
				<el-breadcrumb-item><span class="cu" @click="toBack">精品课程</span></el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<div class="Details_box">
			<div class="D_tit">{{info.cou_name}}</div>
			<div class="D_Date">年份：{{info.cou_year}}</div>
			<div class="D_Con" v-html="info.cou_description">
			</div>
			<div class="D_prev" v-if="prev" @click="toDetails('prev')">上一篇：{{prev.cou_name}}</div>
			<div class="D_next" v-if="next" @click="toDetails('next')">下一篇：{{next.cou_name}}</div>
			<div class="D_back" @click="toBack">返回列表</div>
		</div>
	</div>
</template>

<script>
	import Bus from '/src/views/js/bus.js'
	export default {
		data() {
			return {
				info:{},//详情
				next:{},//下一篇
				prev:{},//上一篇
				are_code:"",//城市code
			}
		},
		watch: {
			'$route'(to, from) {
				if (to.query) {
					//获取新闻详情
					this.getNewsDetails()
				}
			}
		},
		methods: {
			//获取新闻详情
			getNewsDetails(){
				this.$http.post(this.PublicJs.publicPath + "/api/website.index/courseDetail.html", {
					ids:this.$route.query.ids,
					are_code:this.are_code
				}, {
					emulateJSON: true
				}).then(response => {
					if(response.data.code == 1){
						this.info = response.data.data.data;
						this.next = response.data.data.next;
						this.prev = response.data.data.prev;
					}else{
						this.$message({
							message: response.data.msg,
							type: 'warning',
							showClose: true
						});
					}
				}, response => {
					console.log(response)
				});
			},
			//跳转首页
			toHome(){
				this.$router.push({
					path: "/"
				});
				Bus.$emit('busIsHome', true);//是否为省平台
				Bus.$emit('busmenuChose', "");
				window.sessionStorage.setItem('isHome',true)
				window.sessionStorage.setItem('menuChose',"")
			},
			//返回列表
			toBack(){
				this.$router.push({
					path: "/WcourseList"
				});
			},
			//上一篇、下一篇
			toDetails(type){
				var ids = "";
				if(type == "prev"){
					ids = this.PublicJs.Encrypt(this.prev.cou_id)
				}else{
					ids = this.PublicJs.Encrypt(this.next.cou_id)
				}
				this.$router.push({
					path: "/courseDetails",
					query: {
						ids:ids
					}
				});
			}
		},
		created() {
			if(this.$cookies.get("are_code")){
				this.are_code = this.$cookies.get("are_code")
			}
			//获取新闻详情
			this.getNewsDetails()
		},
	}
</script>

<style scoped="scoped">
	.newsDetails_box{
		width: 1200px;
		margin: 0 auto;
		padding-top: 36px;
		padding-bottom: 86px;
	}
	.crumbs{
		font-size: 14px;
	}
	.el-breadcrumb{
		display: inline-block;
		vertical-align: top;
	}
	.cu{
		cursor: pointer;
	}
	.current{
		vertical-align: top;
		line-height: 1;
	}
	.Wtit{
		color: #4d6ef2;
	}
	.Details_box{
		width: 1110px;
		margin: 0 auto;
	}
	.D_tit{
		text-align: center;
		font-size: 16px;
		color: #333;
		font-weight: bold;
		line-height: 88px;
	}
	.D_Date{
		text-align: center;
		font-size: 12px;
		color: #999;
		margin-bottom: 30px;
	}
	.D_Con{
		border-top: 1px solid #EEEEEE;
		padding-top: 20px;
		font-size:14px;
		color: #333;
		line-height: 26px;
		margin-bottom: 80px;
	}
	.D_prev,.D_next{
		color: #999;
		font-size:14px;
		line-height: 30px;
		cursor: pointer;
	}
	.D_back{
		color: #2e5cf0;
		font-size: 14px;
		margin-top: 20px;
		cursor: pointer;
	}
</style>
<style>
	.newsDetails_box .el-breadcrumb__inner a:hover, .el-breadcrumb__inner.is-link:hover{
		color: #4d6ef2;
	}
</style>
